<template>
  <div>
    <v-row v-if="documentsList.length > 0 && showEditAndSaveButton" class="mt-10 pl-3 pr-11" justify="space-between">
      <div>
        <p class="title">Upload de Documentos</p>
      </div>
      <div>
        <v-btn class="text--primary" style="background: #d0d2d5" v-if="isDisabledFields" large @click="onClickEdit()">
          <v-icon class="mr-2">
            fas fa-edit
          </v-icon>
          Editar
        </v-btn>
        <v-btn class="text--white" color="primary" v-else large @click="onClickSave()" :disabled="waitingDocumentUpload">
          <v-icon class="mr-2">
            fas fa-save
          </v-icon>
          Salvar
        </v-btn>
      </div>
    </v-row>
    <v-row v-if="documentsList.length > 0" class="mt-10 mb-3 mr-1" style="border-style: dashed; border-width: 2px; border-color: var(--v-primary-base);">
      <v-col cols="12" md="5" v-for="(item, index) in documentsList" :key="index">
        <label class="label"> {{ item.description }}  </label>
        <v-row>
          <v-col>
            <v-file-input
              v-model="item.file"
              @change="uploadDocument(item)"
              placeholder="Clique para adicionar"
              accept="application/pdf, image/jpg, image/jpeg, image/png, image/bmp, image/tiff, image/webp"
              prepend-inner-icon="mdi-paperclip"
              prepend-icon=""
              color="textPrimary"
              outlined
              :disabled="waitingDocumentUpload ? true : isDisabledFields"
              :messages="waitingDocumentUpload ? 'Enviando seu documento... Por favor, aguarde.' : ''"
              :loading="waitingDocumentUpload"
              :rules="item.file ? [rule.validateUploadFiles(item)] : []"
              @click:clear="clearDocument(item)"
            >
            <template v-slot:selection="{}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip
                    small
                    label
                    color="primary"
                    v-on="on"
                  >
                    {{ truncateText(item.file.name, 25) }}
                  </v-chip>
                </template>
                <span>{{ item.file.name }}</span>
              </v-tooltip>
            </template>
            </v-file-input>
          </v-col>
          <v-col class="mb-7" align-self="center">
            <v-btn class="text--white" :loading="item.loadingDownload" color="primary" @click="downloadDocuments(item)">
              Baixar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import DocumentService from '@/services-http/sdi/DocumentService';
import Rules from '@/shared/validators/formRules';

export default {
  name: 'UploadDocumentComponent',
  data: () => ({
    documentsList: [],
    documentsData: [],
    isDisabledFields: true,
    waitingDocumentUpload: false,
    isCriticizedBroker: false,
    isCriticizedCarrier: false,
    isEdit: false,
  }),

  props: {
    showEditAndSaveButton: {
      required: false,
      type: Boolean,
    },
  },

  async mounted() {
    this.verifyTypeOperationForMovement();
    await this.loadDocumentType();
    this.loadDocumentsByMovementRecordId();
  },

  methods: {
    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    async loadDocumentType() {
      const route = this.$route.query;
      const { movementType } = route;
      const { insuranceCarrierId } = route;
      const { insuranceCarrierIds } = route;
      const { beneficiaryType } = route;

      const query = `movementType=${movementType}&insuranceCarrierId=${insuranceCarrierId ? insuranceCarrierId : insuranceCarrierIds}&beneficiaryType=${beneficiaryType}`;
      await this.findDocumentType(query);
    },
    async findDocumentType(query) {
      await this.documentTypeService.FindAllDocumentType(query).then((response) => {
        this.documentsList = response.data.content.map((element) => ({
          id: element.id,
          name: element.name,
          description: element.description,
          file: null,
        }));
      });
    },
    async uploadDocument(item) {
      if (item) {
        if ((item.file && !this.rule.isValidFileSize(item.file.size)) || (item.file && item.file.type && (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpg' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png' && item.file.type !== 'image/bmp' && item.file.type !== 'image/tiff' && item.file.type !== 'image/webp'))) {
          return;
        }
        if (item.file !== null && typeof item.file !== 'undefined') {
          await this.clearDocument(item);
          this.waitingDocumentUpload = true;
          const documentTypeId = item.documentTypeId ? item.documentTypeId : item.id;
          await this.documentService.UploadDocument(documentTypeId, item.file).then((response) => {
            this.waitingDocumentUpload = false;
            this.documentsData.push({
              id: response.data,
              name: item.name,
              file: item.file,
              type: item.file.type,
            });
            // eslint-disable-next-line no-param-reassign
            item.idRecentlyUploaded = response.data;
          }).catch(() => {
            this.waitingDocumentUpload = false;
          });

          if (this.movementRecord) {
            this.$emit('observerDocumentData', this.documentsData);
          }
        } else {
          this.clearDocument(item);
        }
      }
    },
    async clearDocument(item) {
      if (item) {
        if (this.documentsData && this.documentsData.length > 0) {
          const documentIndex = this.documentsData.findIndex((document) => document.id === item.id);
          if (documentIndex >= 0) {
            this.documentsData.splice(documentIndex, 1);
            this.$emit('observerDocumentData', this.documentsData);
          }
        }
      }
    },
    async loadDocumentsByMovementRecordId() {
      if (sessionStorage.getItem('movementRecord')) {
        this.movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
        if (this.$route.name === 'MovementDetail') {
          this.isDisabledFields = true;
        } else {
          this.isDisabledFields = false;
        }
        const queryString = `?movementRecordId=${this.movementRecord.id}`;
        await this.documentService.FindDocumentByFilters(queryString).then(async (response) => {
          if (response && response.data && response.data.length > 0) {
            if (this.documentsList && this.documentsList.length > 0) {
              this.documentsList.forEach((documentList) => {
                response.data.forEach((document) => {
                  if (documentList.name === document.documentType.name) {
                    // eslint-disable-next-line no-param-reassign
                    documentList.file = new File([document.name], document.name, { type: 'image/png' });
                    // eslint-disable-next-line no-param-reassign
                    documentList.documentTypeId = documentList.id;
                    // eslint-disable-next-line no-param-reassign
                    documentList.id = document.id;
                    // eslint-disable-next-line no-param-reassign
                    documentList.name = document.name;
                    // eslint-disable-next-line no-param-reassign
                    documentList.loadingDownload = false;
                  }
                });
              });
            }
          }
          this.loadDocumentsInData();
        });
      }
    },
    async loadDocumentsInData() {
      this.documentsList.forEach((document) => {
        if (document && document.documentTypeId) {
          this.documentsData.push(document);
        }
      });

      if (this.showEditAndSaveButton) {
        this.onClickSave();
      }
      this.$emit('observerDocumentData', this.documentsData);
    },
    onClickEdit() {
      this.isDisabledFields = false;
      this.$emit('disabledUploadDocuments', this.isDisabledFields);
    },
    onClickSave() {
      sessionStorage.setItem('documents', JSON.stringify(this.documentFormatted()));

      this.isDisabledFields = true;
      this.$emit('disabledUploadDocuments', this.isDisabledFields);
    },
    documentFormatted() {
      const documentFormatted = this.documentsList.filter((element) => element.file !== null && typeof element.file !== 'undefined').map((element) => ({ id: element.idRecentlyUploaded ? element.idRecentlyUploaded : element.id }));
      return documentFormatted;
    },
    async downloadDocuments(item) {
      if (item && item.file !== null) {
        // eslint-disable-next-line no-param-reassign
        item.loadingDownload = true;
        this.$forceUpdate();
        const id = item.idRecentlyUploaded ? item.idRecentlyUploaded : item.id;
        const queryString = `?idDocuments=${id}`;

        await this.documentService.DownloadFileDocuments(queryString).then((response) => {
          if (response) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            const contentDispositionHeader = response.headers['content-disposition'];
            const name = this.validateNameInResponseHeaders(contentDispositionHeader);
            const nameDecoded = decodeURIComponent(name);

            fileLink.href = fileURL;
            fileLink.setAttribute('download', nameDecoded);
            document.body.appendChild(fileLink);
            fileLink.click();

            this.selectedItems = [];
            // eslint-disable-next-line no-param-reassign
            item.loadingDownload = false;
            this.$forceUpdate();
          }
        }).catch(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        }).finally(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        });
      }
    },
    validateNameInResponseHeaders(contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      let name;
      if (fileName) {
        name = fileName;
      } else if (this.selectedItems.length === 1) {
        const firstSelectedDocumentId = this.selectedItems[0].id;
        const matchingDocument = this.documents.find((element) => element.id === firstSelectedDocumentId);
        name = matchingDocument ? matchingDocument.name : null;
      } else {
        name = 'filename.zip';
      }

      return name;
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
      }
      return text;
    },
  },

  created() {
    this.documentService = new DocumentService();
    this.documentTypeService = new DocumentTypeService();
    this.rule = new Rules();
  },
};
</script>
