<template style="background: #E5E5E5">
  <div class="mr-4">
    <v-row class="mt-10 px-3" justify="space-between">
      <div>
        <p class="title">Informações Complementares do Beneficiário</p>
      </div>
      <div class="mb-8">
        <v-btn class="text--primary" style="background: #d0d2d5" v-if="isDisabledFields" large @click="onClickEdit()">
          <v-icon class="mr-2">
            fas fa-edit
          </v-icon>
          Editar
        </v-btn>
        <v-btn class="text--white" color="primary" v-else large @click="onClickSaveSessionStorage()">
          <v-icon class="mr-2">
            fas fa-save
          </v-icon>
          Salvar
        </v-btn>
      </div>
    </v-row>
    <v-form ref="formRegister" v-model="isValidForm" lazy-validation>
      <v-row class="mt-5">
        <v-col cols="12" md="6" lg="3">
          <div class="d-flex flex-column">
            <v-text-field
              outlined
              label="Data de Nascimento"
              v-model="beneficiary.birthday"
              append-icon="fas fa-calendar-alt"
              placeholder="DD/MM/YYYY"
              v-mask="'##/##/####'"
              color="textPrimary"
              :disabled="isDisabledFields"
              :rules="beneficiary.birthday ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
              validate-on-blur
              :class="verifyFieldInArray('birthDate') ? 'alterationColorText alterationColorBorder' : ''"
            />
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-autocomplete
            :items="maritalStatus"
            v-model="beneficiary.maritalStatus"
            label="Estado Civil"
            placeholder="Escolha o estado civil"
            item-text="description"
            item-value="id"
            outlined
            hide-details
            color="textPrimary"
            item-color="textPrimary"
            append-icon="fas fa-chevron-down"
            :disabled="isDisabledFields"
            clearable
            :class="verifyFieldInArray('maritalStatus') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-autocomplete
            :items="genders"
            v-model="beneficiary.gender"
            label="Gênero"
            placeholder="Escolha o gênero"
            item-text="description"
            item-value="id"
            outlined
            hide-details
            color="textPrimary"
            item-color="textPrimary"
            append-icon="fas fa-chevron-down"
            :disabled="isDisabledFields"
            clearable
            :class="verifyFieldInArray('gender') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            label="Nome da mãe"
            placeholder="Informe o nome da Mãe"
            maxlength="70"
            v-model.trim="beneficiary.motherName"
            outlined
            color="textPrimary"
            :disabled="isDisabledFields"
            :rules="beneficiary.motherName ? [rule.name, rule.validateIsAlphanumeric] : []"
            validate-on-blur
            @blur="beneficiary.motherName = formatter.formatToTitleCase(beneficiary.motherName)"
            :class="verifyFieldInArray('motherName') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            label="CNS"
            placeholder="Informe o CNS"
            v-model="beneficiary.cns"
            v-mask="'###############'"
            outlined
            color="textPrimary"
            :disabled="isDisabledFields"
            :rules="beneficiary.cns ? [rule.cns] : []"
            validate-on-blur
            :class="verifyFieldInArray('cns') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            label="Declaração de Nascido Vivo (DNV)"
            placeholder="Informe o DNV"
            v-mask="'##-########-#'"
            v-model="beneficiary.dnv"
            outlined
            color="textPrimary"
            :disabled="isDisabledFields"
            :rules="beneficiary.dnv ? [rule.dnv] : []"
            validate-on-blur
            :class="verifyFieldInArray('dnv') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            label="Nome da empresa"
            v-model.trim="beneficiary.companyName"
            :disabled="isDisabledFields"
            placeholder="Informe o nome"
            color="textPrimary"
            outlined
            @blur="beneficiary.companyName = formatter.removeAccents(beneficiary.companyName)"
            :rules="beneficiary.companyName ? [rule.validateCompanyName, rule.validateMaxTextSize(beneficiary.companyName, 255)] : []"
            validate-on-blur
            :class="verifyFieldInArray('companyName') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            label="CNPJ da empresa"
            v-model="beneficiary.companyDocumentNumber"
            :disabled="isDisabledFields"
            v-mask="'##.###.###/####-##'"
            placeholder="CNPJ da empresa"
            color="textPrimary"
            outlined
            :rules="beneficiary.companyDocumentNumber ? [rule.cnpj] : []"
            validate-on-blur
            :class="verifyFieldInArray('companyDocumentNumber') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-autocomplete
            :items="employmentRelationships"
            v-model="beneficiary.employmentRelationship"
            label="Vínculo Empregaticio"
            placeholder="Escolha o vínculo"
            item-text="description"
            item-value="id"
            outlined
            hide-details
            color="textPrimary"
            item-color="textPrimary"
            append-icon="fas fa-chevron-down"
            :disabled="isDisabledFields"
            clearable
            :class="verifyFieldInArray('employmentRelationship') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            label="Carteira de Trabalho"
            placeholder="Informe a carteira de trabalho"
            v-mask="'#######'"
            v-model="beneficiary.workCard"
            outlined
            color="textPrimary"
            :disabled="isDisabledFields"
            :class="verifyFieldInArray('professionalCardNumber') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            v-model="beneficiary.pisPasep"
            label="PIS / PASEP"
            placeholder="Informe o PIS / PASEP"
            :rules="beneficiary.pisPasep ? [rule.validatePis] : []"
            validate-on-blur
            v-mask="'###.#####.##-#'"
            outlined
            color="textPrimary"
            :disabled="isDisabledFields"
            :class="verifyFieldInArray('pisPasep') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <template v-if="!isFreeMovement">
          <v-col cols="12" md="6" lg="3">
            <v-text-field
              label="Matrícula"
              placeholder="Informe a matrícula"
              v-model="beneficiary.registration"
              maxlength="50"
              outlined
              color="textPrimary"
              :disabled="isDisabledFields"
              :success-messages="previousRegistration && previousRegistration !== beneficiary.registration ? `Matrícula Atual: ${previousRegistration}` : null"
              :class="verifyFieldInArray('registrationPlate') ? 'alterationColorText alterationColorBorder' : ''"
            />
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-text-field
              label="Complemento da Matrícula"
              placeholder="Informe o complemento"
              v-model="beneficiary.registrationComplement"
              maxlength="50"
              outlined
              color="textPrimary"
              :disabled="isDisabledFields"
              :class="verifyFieldInArray('registrationComplement') ? 'alterationColorText alterationColorBorder' : ''"
            />
          </v-col>
        </template>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            label="Cargo"
            placeholder="Informe o cargo"
            v-model="beneficiary.job"
            outlined
            color="textPrimary"
            :disabled="isDisabledFields"
            :class="verifyFieldInArray('insuredPosition') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>        
        <v-col cols="12" md="6" lg="3">
          <v-autocomplete
            :items="nationalitys"
            v-model="beneficiary.nationality"
            label="Nacionalidade"
            placeholder="Escolha a nacionalidade"
            item-text="text"
            item-value="value"
            outlined
            hide-details
            color="textPrimary"
            item-color="textPrimary"
            append-icon="fas fa-chevron-down"
            :disabled="isDisabledFields"
            clearable
            :class="verifyFieldInArray('nationality') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-autocomplete
            v-model="beneficiary.stateBirth"
            :items="states"
            item-text="label"
            item-value="label"
            outlined
            label="UF de Nascimento"
            color="textPrimary"
            item-color="textPrimary"
            append-icon="fas fa-chevron-down"
            :disabled="isDisabledFields"
            clearable
            :class="verifyFieldInArray('stateBirth') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            label="Peso(kg)"
            v-mask="'###'"
            type="number"
            min="1"
            placeholder="Informe o peso"
            v-model="beneficiary.weight"
            @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
            @input="beneficiary.weight = formatter.removeInvalidNumber(beneficiary.weight)"
            @blur="validateNumbersGreaterThanOne(beneficiary.weight, 'fieldCheckedWeight')"
            id="fieldCheckedWeight"
            outlined
            color="textPrimary"
            :disabled="isDisabledFields"
            :rules="controlWeight ? ['Favor informar um número válido'] : beneficiary.weight ? [rule.validatesNumberGreaterThanOrEqualToZero(beneficiary.weight)] : []"
            :class="verifyFieldInArray('weight') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            label="Altura(cm)"
            v-mask="'###'"
            type="number"
            min="1"
            placeholder="Informe a altura"
            v-model="beneficiary.height"
            @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
            @input="beneficiary.height = formatter.removeInvalidNumber(beneficiary.height);"
            @blur="validateNumbersGreaterThanOne(beneficiary.height, 'fieldCheckedHeight')"
            id="fieldCheckedHeight"
            outlined
            color="textPrimary"
            :disabled="isDisabledFields"
            :rules="controlHeight ? ['Favor informar um número válido'] : beneficiary.height ? [rule.validatesNumberGreaterThanOrEqualToZero(beneficiary.height)] : []"
            :class="verifyFieldInArray('height') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12" md="6" lg="3" v-if="beneficiary.nationality !== 'BRASILEIRA'">
          <v-text-field
            label="RNE/RNM"
            v-mask="'#########'"
            placeholder="Informe o RNE/RNM"
            v-model="beneficiary.rne"
            outlined
            color="textPrimary"
            :disabled="isDisabledFields"
            :class="verifyFieldInArray('rne') ? 'alterationColorText alterationColorBorder' : ''"
          />
        </v-col>
        <v-col cols="12">
          <label class="title">Dados de Contato</label>
          <v-row class="my-5">
            <v-col cols="12" md="6" lg="3">
              <v-text-field
                label="Telefone Residencial"
                placeholder="Informe o número"
                v-model="beneficiary.telephone"
                lazy-validation
                v-mask="'(##) ####-####'"
                :rules="beneficiary.telephone ? [rule.telephone] : []"
                validate-on-blur
                outlined
                color="textPrimary"
                :disabled="isDisabledFields"
                :class="verifyFieldInArray('homephoneDdd' + ' ' + 'homephoneNumber') ? 'alterationColorText alterationColorBorder' : ''"
              />
            </v-col>
            <v-col cols="12" md="6" lg="3">
              <v-text-field
                label="Celular"
                placeholder="Informe o número"
                v-model="beneficiary.cellphone"
                v-mask="'(##) #####-####'"
                :rules="beneficiary.cellphone ? [rule.cellphone] : []"
                validate-on-blur
                outlined
                color="textPrimary"
                :disabled="isDisabledFields"
                :class="verifyFieldInArray('cellphoneDdd' + ' ' + 'cellphoneNumber') ? 'alterationColorText alterationColorBorder' : ''"
              />
            </v-col>
            <v-col cols="12" md="6" lg="3">
              <v-text-field
                label="E-Mail"
                placeholder="exemplo@email.com.br"
                v-model="beneficiary.email"
                :rules="beneficiary.email ? [rule.email] : []"
                validate-on-blur
                outlined
                color="textPrimary"
                :disabled="isDisabledFields"
                :class="verifyFieldInArray('email') ? 'alterationColorText alterationColorBorder' : ''"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <label class="title">Identidade</label>
          <v-row class="my-5">
            <v-col cols="3">
              <label class="label">Natureza</label>
              <v-autocomplete
                class="mt-2"
                v-model="beneficiary.identityDocumentNature"
                :items="identityDocumentNatureTypes"
                :disabled="isDisabledFields"
                @input="beneficiary.identityDocumentNumber = null"
                placeholder="Informe a natureza do documento"
                color="textPrimary"
                outlined
                clearable
                :class="verifyFieldInArray('natureIdentifyDocument') ? 'alterationColorText alterationColorBorder' : ''"
              />
            </v-col>
            <v-col cols="3">
              <label class="label">Documento</label>
              <v-text-field
                v-model="beneficiary.identityDocumentNumber"
                :rules="beneficiary.identityDocumentNumber ? [rule.rgMaxDigit(beneficiary.identityDocumentNature, beneficiary.identityDocumentNumber)] : []"
                v-mask="'NNNNNNNNNNNNNNN'"
                class="mt-2"
                placeholder="Informe o número do documento"
                outlined
                color="textPrimary"
                :disabled="isDisabledFields"
                :class="verifyFieldInArray('rgDocumentIdentify') ? 'alterationColorText alterationColorBorder' : ''"
              />
            </v-col>
            <v-col cols="3">
              <label class="label">Data de Expedição</label>
              <div class="d-flex flex-column">
                <v-text-field
                  outlined
                  v-model="beneficiary.identityDocumentEmissionDate"
                  append-icon="fas fa-calendar-alt"
                  placeholder="DD/MM/YYYY"
                  v-mask="'##/##/####'"
                  color="textPrimary"
                  class="mt-2"
                  :disabled="isDisabledFields"
                  :rules="beneficiary.identityDocumentEmissionDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                  :class="verifyFieldInArray('rgDocumentIssueDate') ? 'alterationColorText alterationColorBorder' : ''"
                />
              </div>
            </v-col>
            <v-col cols="3">
              <label class="label">Orgão Emissor</label>
              <v-text-field
                maxlength="8"
                v-mask="'AAAAAAAA'"
                @input="beneficiary.identityDocumentIssuer = beneficiary.identityDocumentIssuer.toUpperCase()"
                v-model="beneficiary.identityDocumentIssuer"
                class="mt-2"
                placeholder="Informe o órgão emissor"
                outlined
                color="textPrimary"
                :disabled="isDisabledFields"
                :class="verifyFieldInArray('rgDocumentIssuingBody') ? 'alterationColorText alterationColorBorder' : ''"
              />
            </v-col>
          </v-row>
          <v-row class="my-5">
            <v-col cols="3">
              <label class="label">UF Emissor</label>
              <v-autocomplete
                v-model="beneficiary.identityDocumentState"
                :items="states"
                class="mt-2"
                item-text="label"
                item-value="label"
                outlined
                placeholder="UF emissor"
                color="textPrimary"
                item-color="textPrimary"
                append-icon="fas fa-chevron-down"
                :disabled="isDisabledFields"
                clearable
                :class="verifyFieldInArray('documentStateIssuer') ? 'alterationColorText alterationColorBorder' : ''"
              />
            </v-col>
            <v-col cols="3">
              <label class="label">País Emissor</label>
                <v-autocomplete
                  v-model="beneficiary.identityDocumentCountry"
                  :disabled="isDisabledFields"
                  :items="country"
                  class="mt-2"
                  :class="verifyFieldInArray('documentCountryIssue') ? 'alterationColorText alterationColorBorder' : ''"
                  item-text="text"
                  item-value="value"
                  outlined
                  placeholder="País emissor"
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  clearable
                />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import { VMoney } from 'v-money';
import GenderService from '@/services-http/contract/GenderService';
import MaritalStatusService from '@/services-http/contract/MaritalStatusService';
import lodash from 'lodash';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import ChangedFieldsMixin from '@/shared/mixins/highlightedChangedFields/changedFieldsMixin';
import EmploymentRelationshipService from '@/services-http/sdi/EmploymentRelationshipService';

export default {
  name: 'BeneficiaryAdditionalInformationComponent',
  directives: { money: VMoney },
  data: () => ({
    beneficiary: {
      birthday: null,
      maritalStatus: null,
      gender: null,
      job: null,
      // department: null,
      registration: '',
      registrationComplement: null,
      previousRegistration: '',
      previousRegistrationComplement: '',
      // capacityCode: null,
      // capacityDescription: null,
      employmentRelationship: null,
      motherName: null,
      nationality: null,
      pisPasep: null,
      email: null,
      workCard: null,
      cns: null,
      weight: null,
      height: null,
      dnv: null,
      rne: null,
      cellphone: null,
      telephone: null,
      identityDocumentNature: null,
      identityDocumentNumber: null,
      identityDocumentEmissionDate: null,
      identityDocumentIssuer: null,
      identityDocumentState: null,
      identityDocumentCountry: null,
      companyDocumentNumber: null,
      companyName: null,
    },
    previousRegistration: null,
    previousRegistrationComplement: null,
    beneficiaryDefault: null,
    employmentRelationshipService: null,
    employmentRelationships: [],
    country: [
      { value: 'BRASIL', text: 'Brasil' },
      { value: 'OUTROS', text: 'Outros' },
    ],
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      precision: 2,
      masked: false,
    },
    identityDocumentNatureTypes: [
      'RG',
      'Passaporte',
      'RNE',
    ],
    genders: [],
    isValidForm: true,
    isDisabledFields: true,
    maritalStatus: [],
    nationalitys: [
      { value: 'BRASILEIRA', text: 'Brasileira' },
      { value: 'OUTROS', text: 'Outros' },
    ],
    sessionStorageName: 'beneficiaryAdditionalInfoUpdated',
    controlHeight: false,
    controlWeight: false,
    isFreeMovement: false,
    beneficiaryHolderAlteration: {},
    movementRecord: {},
    isCriticizedBroker: false,
    isCriticizedCarrier: false,
    isEdit: false,
  }),

  mixins: [
    ConstantsMixin,
    ChangedFieldsMixin,
  ],

  props: { changedFields: { type: Array } },

  watch: {
    beneficiary: {
      handler(val) {
        if (val.natureIdentifyDocument === '') {
          this.beneficiary.natureIdentifyDocument = null;
        }
        if (val.identityDocumentNumber === '') {
          this.beneficiary.identityDocumentNumber = null;
        }
        if (val.identityDocumentEmissionDate === '') {
          this.beneficiary.identityDocumentEmissionDate = null;
        }
        if (val.identityDocumentIssuer === '') {
          this.beneficiary.identityDocumentIssuer = null;
        }
        if (val.identityDocumentCountry === '') {
          this.beneficiary.identityDocumentCountry = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async mounted() {
    this.loadGenders();
    this.loadMaritalStatus();
    if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
      this.isFreeMovement = true;
    } else {
      await this.onClickEdit();
    }
    await this.loadEmploymentRelationship();
    await this.loadContractedPlanInfo();
  },

  methods: {
    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    async loadEmploymentRelationship() {
      await this.employmentRelationshipService.FindAll().then((response) => {
        if (response && response.data) {
          this.employmentRelationships = response.data;
        }
      });
    },
    async loadContractedPlanInfo() {
      if (sessionStorage.getItem('holderAlteration')) {
        this.beneficiaryHolderAlteration = JSON.parse(sessionStorage.getItem('holderAlteration'));
        if (this.beneficiaryHolderAlteration) {
          this.previousRegistration = this.beneficiaryHolderAlteration.registrationPlate ? this.beneficiaryHolderAlteration.registrationPlate : null;
          this.previousRegistrationComplement = this.beneficiaryHolderAlteration.registrationComplement ? this.beneficiaryHolderAlteration.registrationComplement : null;
          this.beneficiary = {
            birthday: this.beneficiaryHolderAlteration.physicalPersonBirthDate ? this.formatter.formatDate(this.beneficiaryHolderAlteration.physicalPersonBirthDate) : null,
            maritalStatus: this.beneficiaryHolderAlteration.maritalStatusId ? this.beneficiaryHolderAlteration.maritalStatusId : null,
            gender: this.beneficiaryHolderAlteration.genderId ? this.beneficiaryHolderAlteration.genderId : null,
            motherName: this.beneficiaryHolderAlteration.mothersName ? this.formatter.formatToTitleCase(this.beneficiaryHolderAlteration.mothersName) : null,
            cns: this.beneficiaryHolderAlteration.cns ? this.beneficiaryHolderAlteration.cns : null,
            dnv: this.beneficiaryHolderAlteration.dnv ? this.beneficiaryHolderAlteration.dnv : null,
            // department: this.beneficiaryHolderAlteration.costCenterDepartment ? this.beneficiaryHolderAlteration.costCenterDepartment : null,
            // capacityCode: this.beneficiaryHolderAlteration.taxAllocationCode ? this.beneficiaryHolderAlteration.taxAllocationCode : null,
            // capacityDescription: this.beneficiaryHolderAlteration.taxAllocationDescription ? this.beneficiaryHolderAlteration.taxAllocationDescription : null,
            employmentRelationship: this.beneficiaryHolderAlteration.employmentRelationship ? this.beneficiaryHolderAlteration.employmentRelationship : null,
            workCard: this.beneficiaryHolderAlteration.professionalCardNumber ? this.beneficiaryHolderAlteration.professionalCardNumber : null,
            pisPasep: this.beneficiaryHolderAlteration.pisPasep ? this.beneficiaryHolderAlteration.pisPasep : null,
            registration: this.beneficiaryHolderAlteration.registrationPlate ? this.beneficiaryHolderAlteration.registrationPlate : null,
            registrationComplement: this.beneficiaryHolderAlteration.registrationComplement ? this.beneficiaryHolderAlteration.registrationComplement : null,
            previousRegistration: this.beneficiaryHolderAlteration.registrationPlate ? this.beneficiaryHolderAlteration.registrationPlate : null,
            previousRegistrationComplement: this.beneficiaryHolderAlteration.registrationComplement ? this.beneficiaryHolderAlteration.registrationComplement : null,
            job: this.beneficiaryHolderAlteration.insuredPosition ? this.beneficiaryHolderAlteration.insuredPosition : null,
            nationality: this.beneficiaryHolderAlteration.nationality ? this.beneficiaryHolderAlteration.nationality : null,
            weight: this.beneficiaryHolderAlteration.weight,
            height: this.beneficiaryHolderAlteration.height,
            rne: this.beneficiaryHolderAlteration.rne ? this.beneficiaryHolderAlteration.rne : null,
            telephone: this.beneficiaryHolderAlteration.homephoneDdd && this.beneficiaryHolderAlteration.homephoneNumber ? this.beneficiaryHolderAlteration.homephoneDdd + this.beneficiaryHolderAlteration.homephoneNumber : null,
            cellphone: this.beneficiaryHolderAlteration.cellphoneDdd && this.beneficiaryHolderAlteration.cellphoneNumber ? this.beneficiaryHolderAlteration.cellphoneDdd + this.beneficiaryHolderAlteration.cellphoneNumber : null,
            email: this.beneficiaryHolderAlteration.email ? this.beneficiaryHolderAlteration.email : null,
            identityDocumentNature: this.beneficiaryHolderAlteration.natureIdentifyDocument ? this.beneficiaryHolderAlteration.natureIdentifyDocument : null,
            identityDocumentNumber: this.beneficiaryHolderAlteration.identityDocumentNumber ? this.beneficiaryHolderAlteration.identityDocumentNumber : null,
            identityDocumentEmissionDate: this.beneficiaryHolderAlteration.identityDocumentEmissionDate ? this.formatter.formatDate(this.beneficiaryHolderAlteration.identityDocumentEmissionDate) : null,
            identityDocumentIssuer: this.beneficiaryHolderAlteration.identityDocumentIssuer ? this.beneficiaryHolderAlteration.identityDocumentIssuer : null,
            identityDocumentState: this.beneficiaryHolderAlteration.documentStateIssuer ? this.beneficiaryHolderAlteration.documentStateIssuer : null,
            identityDocumentCountry: this.beneficiaryHolderAlteration.documentCountryIssue ? this.beneficiaryHolderAlteration.documentCountryIssue : null,
            stateBirth: this.beneficiaryHolderAlteration.stateBirth ? this.beneficiaryHolderAlteration.stateBirth : null,
            companyName: this.beneficiaryHolderAlteration.companyName ? this.formatter.removeAccents(this.beneficiaryHolderAlteration.companyName) : null,
            companyDocumentNumber: this.beneficiaryHolderAlteration.companyDocumentNumber ? this.beneficiaryHolderAlteration.companyDocumentNumber : null,
          };
        }
        setTimeout(() => {
          this.onClickSaveSessionStorage();
        }, 250);
      } else if (sessionStorage.getItem('movementRecord')) {
        await this.mapBeneficiaryMovementRecord();
      }
    },
    async mapBeneficiaryMovementRecord() {
      this.movementRecord = await JSON.parse(sessionStorage.getItem('movementRecord'));
      if (this.movementRecord) {
        this.previousRegistration = this.movementRecord.registrationPlate ? this.movementRecord.registrationPlate : null;
        this.previousRegistrationComplement = this.movementRecord.registrationComplement ? this.movementRecord.registrationComplement : null;
        this.beneficiary = {
          birthday: this.movementRecord.birthDate ? this.formatter.formatDate(this.movementRecord.birthDate) : null,
          maritalStatus: this.movementRecord.maritalStatus ? this.movementRecord.maritalStatus : null,
          gender: this.movementRecord.gender ? this.movementRecord.gender : null,
          motherName: this.movementRecord.motherName ? this.formatter.formatToTitleCase(this.movementRecord.motherName) : null,
          cns: this.movementRecord.cns ? this.movementRecord.cns : null,
          dnv: this.movementRecord.dnv ? this.movementRecord.dnv : null,
          companyName: this.movementRecord.companyName ? this.movementRecord.companyName : null,
          companyDocumentNumber: this.movementRecord.companyDocumentNumber ? this.movementRecord.companyDocumentNumber : null,
          // department: this.movementRecord.costCenterDepartment ? this.movementRecord.costCenterDepartment : null,
          // capacityCode: this.movementRecord.taxAllocationCode ? this.movementRecord.taxAllocationCode : null,
          // capacityDescription: this.movementRecord.taxAllocationDescription ? this.movementRecord.taxAllocationDescription : null,
          employmentRelationship: this.movementRecord.employmentRelationshipId ? String(this.movementRecord.employmentRelationshipId) : null,
          workCard: this.movementRecord.professionalCardNumber ? this.movementRecord.professionalCardNumber : null,
          pisPasep: this.movementRecord.pisPasep ? this.movementRecord.pisPasep : null,
          registration: this.movementRecord.registrationPlate ? this.movementRecord.registrationPlate : null,
          registrationComplement: this.movementRecord.registrationComplement ? this.movementRecord.registrationComplement : null,
          previousRegistration: this.movementRecord.registrationPlate ? this.movementRecord.registrationPlate : null,
          previousRegistrationComplement: this.movementRecord.registrationComplement ? this.movementRecord.registrationComplement : null,
          job: this.movementRecord.insuredPosition ? this.movementRecord.insuredPosition : null,
          nationality: this.movementRecord.nationality ? this.movementRecord.nationality : null,
          stateBirth: this.movementRecord.stateBirth ? this.movementRecord.stateBirth : null,
          weight: this.movementRecord.weight,
          height: this.movementRecord.height,
          rne: this.movementRecord.rne ? this.movementRecord.rne : null,
          telephone: this.movementRecord.homephoneDDD && this.movementRecord.homephoneNumber ? this.movementRecord.homephoneDDD + this.movementRecord.homephoneNumber : null,
          cellphone: this.movementRecord.cellphoneDDD && this.movementRecord.cellphoneNumber ? this.movementRecord.cellphoneDDD + this.movementRecord.cellphoneNumber : null,
          email: this.movementRecord.email ? this.movementRecord.email : null,

          identityDocumentNature: this.movementRecord.natureIdentifyDocument ? this.movementRecord.natureIdentifyDocument : null,
          identityDocumentNumber: this.movementRecord.rgDocumentIdentify ? this.movementRecord.rgDocumentIdentify : null,
          identityDocumentEmissionDate: this.movementRecord.rgDocumentIssueDate ? this.formatter.formatDate(this.movementRecord.rgDocumentIssueDate) : null,
          identityDocumentIssuer: this.movementRecord.rgDocumentIssuingBody ? this.movementRecord.rgDocumentIssuingBody : null,
          identityDocumentState: this.movementRecord.documentStateIssuer ? this.movementRecord.documentStateIssuer : null,
          identityDocumentCountry: this.movementRecord.documentCountryIssue ? this.movementRecord.documentCountryIssue : null,
        };

      }
      setTimeout(() => {
        this.onClickSaveSessionStorage();
      }, 1000);
    },
    async loadGenders() {
      await this.genderService.FindAll().then((response) => {
        if (response && response.data) {
          this.genders = response.data;
        }
      });
    },
    async loadMaritalStatus() {
      await this.maritalStatusService.FindAll().then((response) => {
        if (response && response.data) {
          this.maritalStatus = response.data;
        }
      });
    },
    async onClickEdit() {
      this.isDisabledFields = false;
      this.$emit('disabledAdditionalInformation', this.isDisabledFields);
    },
    async onClickSaveSessionStorage() {
      if (!this.$refs.formRegister.validate()) {
        return;
      }
      const beneficiaryClone = {
        telephone: lodash.cloneDeep(this.beneficiary.telephone),
        cellphone: lodash.cloneDeep(this.beneficiary.cellphone),
        dnv: lodash.cloneDeep(this.beneficiary.dnv),
      };
      this.beneficiary.cellphone = this.beneficiary.cellphone ? this.beneficiary.cellphone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null;
      this.beneficiary.telephone = this.beneficiary.telephone ? this.beneficiary.telephone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null;
      this.beneficiary.dnv = this.beneficiary.dnv ? this.formatter.unmaskDnv(this.beneficiary.dnv) : null;

      let formatCompanyDocumentNumber = this.beneficiary.companyDocumentNumber ? this.beneficiary.companyDocumentNumber : null;
      formatCompanyDocumentNumber = formatCompanyDocumentNumber ? this.formatter.formatCnpj(formatCompanyDocumentNumber) : null;

      this.beneficiary.previousRegistration = this.previousRegistration === this.beneficiary.registration ? null : this.previousRegistration;
      this.beneficiary.previousRegistrationComplement = this.previousRegistrationComplement === this.beneficiary.registrationComplement ? null : this.previousRegistrationComplement;
      this.beneficiary.registration = this.beneficiary.registration ? this.beneficiary.registration : null;
      const payload = { ...this.beneficiary };
      payload.birthday = this.beneficiary && this.beneficiary.birthday ? this.formatter.formatDateBRtoString(this.beneficiary.birthday) : null;
      payload.identityDocumentEmissionDate = this.beneficiary && this.beneficiary.identityDocumentEmissionDate ? this.formatter.formatDateBRtoString(this.beneficiary.identityDocumentEmissionDate) : null;
      payload.companyDocumentNumber = formatCompanyDocumentNumber || null;
      sessionStorage.setItem(this.sessionStorageName, JSON.stringify(payload));

      this.beneficiary.telephone = beneficiaryClone.telephone ? beneficiaryClone.telephone : null;
      this.beneficiary.cellphone = beneficiaryClone.cellphone ? beneficiaryClone.cellphone : null;
      this.beneficiary.dnv = beneficiaryClone.dnv ? this.formatter.unmaskDnv(beneficiaryClone.dnv) : null;
      this.isDisabledFields = true;
      this.$emit('disabledAdditionalInformation', this.isDisabledFields);
    },
    verifyNegative(value) {
      let formatValue = value;
      formatValue = formatValue.replace(',', '.');
      if (parseFloat(formatValue) < 0) {
        document.getElementById('fieldChecked').focus();
      }
    },
    validateNumbersGreaterThanOne(value, id) {
      if (parseFloat(value) < 1) {
        document.getElementById(id).focus();
        switch (id) {
          case 'fieldCheckedWeight':
            this.controlWeight = true;
            this.controlHeight = false;
            break;
          case 'fieldCheckedHeight':
            this.controlHeight = true;
            this.controlWeight = false;
            break;
          default:
            this.controlHeight = false;
            this.controlWeight = false;
            break;
        }
      } else {
        this.controlHeight = false;
        this.controlWeight = false;
      }
    },
  },
  async created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.genderService = new GenderService();
    this.maritalStatusService = new MaritalStatusService();
    this.employmentRelationshipService = new EmploymentRelationshipService();
  },
};
</script>

<style scoped>
.btn-dialog {
  width: 200px;
  font-size: 16px;
}
</style>
